import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import inflation_data from './formatted_inflation_data.json';


import { Autocomplete, TextField, List, ListItemText } from '@mui/material';

class CountrySelector extends Component {
  /* Props:
   *  # countries: array<String>
   *  # onCountryChanged: function(country: String)
   *  # country: String
  */
  countrySelected = (event, value) => {
    if (this.props.countries.indexOf(value) > -1) {
      this.setState({
        country: value
      });
      this.props.onCountryChanged(value)
    } else {
      console.log(value + " not in Countries list. Skipping.")
    }
  }
          
  render(){
    return (
      <Autocomplete
        disablePortal
        id="combo-box-countries"
        options={this.props.countries}
        renderInput={(params) => <TextField {...params} label="Country/Demographic" />}
        value={this.props.country}
        onChange={this.countrySelected}
      />
    )
  }
}

class ErrorList extends React.Component {
  render() {
    var errors = Array(0);
    for (let i = 0; i < this.props.errors.length; i++)
    {
      errors.push(<ListItemText primary={this.props.errors[i]} className="error-list-item"/>)
    }
    return (
      <List>
        {errors}
      </List>
    );
  }
}

class InflationResult extends React.Component {
  toFixed(value, precisionArg) {
    var precision = precisionArg || 0,
        power = Math.pow(10, precision),
        absValue = Math.abs(Math.round(value * power)),
        result = (value < 0 ? '-' : '') + String(Math.floor(absValue / power));

    if (precision > 0) {
        var fraction = String(absValue % power),
            padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');
        result += '.' + padding + fraction;
    }
    return result;
  }
  
  render() {
    // calculateFinalAmount
    var country = this.props.country;
    var startYear = this.props.startYear;
    var endYear = this.props.endYear;
    var initialAmount = this.props.initialAmount;

    var errors = Array(0);
    let fallbackCountry = 'World';
    var fallbackInflationRate = 3.8
    var countryInflationData = inflation_data['name_to_country'][country]['inflationPerYear'];
    var inflationFinalAmount = parseFloat(initialAmount);
    let loopCounter = 0;
    var missingYearKeys = Array(0);
    for (let year = startYear; year < endYear; year++) {
      let yearKey = "" + year
      loopCounter++;
      let inflationPercentage = (fallbackInflationRate/100);
      if (isNaN(countryInflationData[yearKey]))
      {
        missingYearKeys.push(yearKey)
        let message = "Missing inflation data for " + country + " for " + year + ". Applying a fallback global inflation of " + fallbackInflationRate + "% for that year.";
        errors.push(message);
        console.log(message);
      }
      else
      {
        inflationPercentage = countryInflationData[yearKey]/100;
      }

      if (!isNaN(inflationPercentage))
      {
        inflationFinalAmount = inflationFinalAmount * (1 + inflationPercentage);
      }
    }
    console.log("Ran the loop for " + loopCounter + " cycles.");


    var deflationFinalAmount = parseFloat(initialAmount);
    loopCounter = 0;
    for (let year = endYear-1; year >= startYear; year--) {
      let yearKey = "" + year
      loopCounter++;
      let deflationPercentage = (fallbackInflationRate/100);
      if (!isNaN(countryInflationData[yearKey]))
      {
        deflationPercentage = countryInflationData[yearKey]/100;
      }

      if (!isNaN(deflationPercentage))
      {
        deflationFinalAmount = deflationFinalAmount / (1 + deflationPercentage);
      }
    }
    console.log("Ran the loop for " + loopCounter + " cycles.");

    return (
      <div>
        <div>
          <h3>Calculated Inflation</h3>
          <div className='big-result'>
            {this.toFixed(inflationFinalAmount, 2)}
          </div>
          <h3>Calculated Deflation</h3>
          <div className='big-result'>
            {this.toFixed(deflationFinalAmount, 2)}
          </div>
          <div className='text-result'>
            Starting with ${this.toFixed(this.props.initialAmount, 2)}  in '{this.props.country}' at the start of {this.props.startYear}, by the start of {this.props.endYear} you would need to have &nbsp;
            <div className='inline-text-result'>${this.toFixed(inflationFinalAmount, 2)}</div> to have an equivelant spending power.<br/><br/>
            Ending with ${this.toFixed(this.props.initialAmount, 2)}  in '{this.props.country}' at the start of {this.props.endYear}, at the start of {this.props.startYear} you would need to have had &nbsp;
                        <div className='inline-text-result'>${this.toFixed(deflationFinalAmount, 2)}</div> to have an equivelant spending power.
          </div>
        </div>
        <ErrorList 
          errors={errors}
        />
      </div>
    )
  }
}

class InflationCalculatorApp extends React.Component {
  constructor(props) {
    super(props);
    this.defaultInitialAmount = 100.0
    this.defaultStartYear = 1981;
    this.defaultEndYear = 2022;
    this.defaultTextFieldName = "Amount"
    this.state = {
      country: 'World',
      startYear: this.defaultStartYear,
      endYear: this.defaultEndYear,
      initialAmount: this.defaultInitialAmount,
      inflationFinalAmount: null,
      warnings: null,

      textFieldError: false,
      textFieldLabel: this.defaultTextFieldName,
      startYearError: false,
      endYearError: false,
      calculationErrors: Array(0),
    };
  }

  countryChanged = (country) => {
    this.setState({
      country: country
    });

  }

  amountChanged = (event) => {
    console.log("Amount Changed to: " + event.target.value);
    var value = event.target.value.trim()
    var number_regex = /^\d+(.\d+)?$/;
    
    var isError = !number_regex.test(value)
    if (isError == true)
    {
      this.setState({
        textFieldError: isError,
        textFieldLabel: "'" + value + "' is not a valid number."
      });
    } else {
      let amount = parseFloat(value)
      this.setState({
        textFieldError: isError,
        textFieldLabel: this.defaultTextFieldName,
        initialAmount: amount,
      });
    }
  }

  startYearChanged = (event) => {
    var value = event.target.value.trim()
    var year_regex = /^\d{4}$/;
    var isError = !year_regex.test(value)
    if (isError === true)
    {
      this.setState({
        startYearError: isError,
      });
    } else {
      let year = parseInt(value);
      this.setState({
        startYearError: isError,
        startYear: year,
      });
    }
  }

  endYearChanged = (event) => {
    var value = event.target.value.trim()
    var year_regex = /^\d{4}$/;
    var isError = !year_regex.test(value)
    if (isError === true)
    {
      this.setState({
        endYearError: isError,
      });
    } else {
      let year = parseInt(value)
      this.setState({
        endYearError: isError,
        endYear: year,
      });
    }
  }

  render() {
    return (
      <div className="form-input">
        <h2>Inflation Calculator</h2>
        <div className="input-form">
          <CountrySelector
            countries={inflation_data['countries']}
            country={this.state.country}
            onCountryChanged={this.countryChanged}
          />
          <TextField
            onChange={this.amountChanged}
            error={this.state.textFieldError}
            label={this.state.textFieldLabel}
            defaultValue={"" + this.defaultInitialAmount}
          />
          <TextField className="text-input-field"
            onChange={this.startYearChanged}
            error={this.state.startYearError}
            label="Start Year"
            defaultValue={"" + this.defaultStartYear}
          />
          <TextField
            onChange={this.endYearChanged}
            error={this.state.endYearError}
            label="End Year"
            defaultValue={"" + this.defaultEndYear}
          />
        </div>
        <InflationResult 
          country={this.state.country}
          initialAmount={this.state.initialAmount}
          startYear={this.state.startYear}
          endYear={this.state.endYear}
        />
      </div>
    )
  }
}

function App () {
  return (
    <div className="App">
      <div id='header'>
        <h1>HelpfulUtils.com</h1>
      </div>
      <InflationCalculatorApp /><br/>
      <div id='footer'>
        The data for this calculator comes from <a href="https://data.worldbank.org/indicator/FP.CPI.TOTL.ZG">worldbank.org</a>
      </div>
    </div>
  );
}

export default App;
